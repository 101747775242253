<template>
  <div class="bu-ju-3" style="background-color: #fff">
    <div class="top">
      <nav-header :title="title" leftUrl="UserHome"></nav-header>
    </div>
    <div
        class="body"
        v-html="dataInfo.content"
    >
    </div>
    <div class="footer">
      <footer-nav/>
    </div>
  </div>
</template>


<script>
//服务大厅
import NavHeader from '@/components/top/NavHeader'
import FooterNav from "../open/footerNav";
import http from "../../api/http";
import _ from 'lodash'

export default {
  name: 'PlatformProduce',
  components: {
    NavHeader,
    FooterNav
  },
  data() {
    return {
      title: '公司主页',
      dataInfo: {},
      pageName: 'PlatformPlatformProduce'
    }
  },
  mounted() {
    this.getLoad()
  },
  methods: {
    // 获取数据
    async getLoad() {
      //获取简介
      let res = await http.get('/gzhphp/fu_wu_shang/getIntro', {
        params: {g_id: this.$route.params.g_id},
        load_error: false
      })
      if (res?.code === 1) {
        if (_.get(res, ['data', 'type']) === '供应商') {
          //是服务商，跳转到服务页面
          this.$router.replace({name: 'FuWuShangInfo', query: {g_id: this.$route.params.g_id}})
        } else {
          this.dataInfo = res.data;
        }
      } else {
        this.$router.replace({name: 'error'})
      }
    },
  }
}
</script>

<style scoped lang="less">
@import "../../assets/da-ting.less";

.page-content .dao-hang {
  height: auto;
}

.lie-biao {
  margin: 15px;
}
.body{
  padding: 10px;
}
</style>
